import  * as React from 'react';
import {Redirect, useParams} from 'react-router-dom';
import {ICredential} from './App';


import './App.css';
interface ICredentialsCallback {
  callback : (props :ICredential) => void,
  fetchLogin : () => void
}

export function Token(myProps: ICredentialsCallback) {
  myProps.callback(useParams() as ICredential)
  myProps.fetchLogin()
  return (<Redirect to="/" /> )
}
