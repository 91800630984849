import  * as React from 'react';
import {Card, Row, Col} from 'antd';
import {Route, BrowserRouter, Switch } from 'react-router-dom'
import { Token} from './Token'
import {Main} from './Main'
import './App.css';
import {IUser} from './IUser'
import Cookies  from 'js-cookie';

export interface ICredential {
  token: string
}

export class App extends React.Component<{}, {loading: boolean, isAuthenticated: boolean, user: IUser}> {
  token = ""

  constructor(props : any) {
    super(props);
    let existingCookie = Cookies.get("token");
    if (existingCookie === undefined) {
      this.token = "";
      this.state = {
        loading: false,
        isAuthenticated: false,
        user: {dn: "",cn: "",sn: "",title: "",givenName: "",displayName: "",memberOf: [],name: "",objectGUID: "",objectSid: "",sAMAccountName: "",mail: ""} as IUser,
      }
      console.log("cookie NOT found" + this.token)
    } else {
      this.token = existingCookie;
      this.state = {
        loading: true,
        isAuthenticated: false,
        user: {dn: "",cn: "",sn: "",title: "",givenName: "",displayName: "",memberOf: [],name: "",objectGUID: "",objectSid: "",sAMAccountName: "",mail: ""} as IUser,
      }
    }
  this.isAuthenticated = this.isAuthenticated.bind(this)
  }

  componentDidMount() {
    if (this.token) {
      this.fetchLogin();
    }
  }

  isAuthenticated() {
    return (this.state.isAuthenticated)
  }

   fetchLogin = () => {
    this.setState({loading: true});
    console.log("https://dev.api.gate.altyor.solutions/login/auth/" + this.token)
    fetch("https://dev.api.gate.altyor.solutions/login/auth/" + this.token,{
          method:'GET',
        }).then(res => {
            console.log(res.status);
        if (res.ok) {
              res.json().then(json => {
                console.log(json);
                this.setState({isAuthenticated : true, loading: false, user: json.user})
              });
            } else {
              console.log(res.status);
              this.setState({isAuthenticated : false, loading: false})
            }
        }).catch(err => {
            this.setState({isAuthenticated : false, loading: false})
            console.log(err)}
)}

  render() {
    return (
          <BrowserRouter>
            <Switch>
              <Route exact path="/:token" render={() => <Token callback={this.setCredential} fetchLogin={this.fetchLogin} />} />
              <PrivateRoute path="/" children={<Main user={this.state.user} />} isLoading={this.state.loading} isAuthenticated={this.state.isAuthenticated}/>
            </Switch>
          </BrowserRouter>
    );
  }

  setCredential = (newCredential : ICredential) => {
    this.token = newCredential.token;
    Cookies.set('token', this.token, {path: "/", expires: 5})
  }
}

const PrivateRoute = ({component,  isLoading, isAuthenticated, ...rest}: any) => {

    if (isLoading) {
      return (<Row type="flex" align="middle" style={{height:"100%"}}>
                <Col span={20} style={{textAlign: 'center', top:'50%', margin:'auto'}}>
                  <Card  bordered={false} loading={true} style={{textAlign: 'center'}}>
                  </Card>
                </Col>
              </Row>);
    }
    if (!isAuthenticated) {
    console.log("REDIRECTION")
     window.location.href = 'https://dev.gate.altyor.solutions'
      return (<div></div>)
    }
    const routeComponent = (props: any) => (
        React.createElement(component, props)
    );
    return <Route {...rest} render={routeComponent}/>;
};
