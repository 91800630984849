import  * as React from 'react';
import { Carousel } from 'antd';
import {IUser} from './IUser'

export class Main extends React.Component <{user : IUser}, {}> {

render () {

  return (

    <Carousel autoplay>
       <div>
         <h3>Hello !</h3>
       </div>
       <div>
         <h3>{this.props.user.name}</h3>
       </div>
       <div>
         <h3>How are you Today ?</h3>
       </div>
     </Carousel>)
  }
}
